import React from "react"

import Layout from "../../../../components/layout-static"
import Seo from "../../../../components/seo"

const Page = () => {
  const metadata = {
    title: "ANIMAL BITE ATTORNEY CT",
    description:
      "Dog bites and animal attacks cause emotional and physical harm to victims. At Bartlett & Grippe, LLC we help clients seek compensation for their injuries. Contact us for help with your case.",
    heroH1: "ANIMAL  BITE & ATTACK ATTORNEY CT",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Dog bite? Attacked by an Animal? Get Help Now!</h2>
            <p className="mb-8">
              Dog Bites and Animal Attacks in CT leave far more than just scars;
              victims are emotionally harmed as well as physically injured. Dog
              bites are the most common animal bites, and many Connecticut
              victims seek compensation for their injuries through the efforts
              of experienced Dog Bite and Animal Attack Attorneys at the law
              firm of Bartlett & Grippe, LLC.
            </p>

            <p className="mb-8">
              Our law firm handles many types of Personal Injury cases,
              including animal bites. Many cases involve dogs that have had a
              history of biting. There are ways to recover your injury expenses
              through legal procedures, including lawsuits. We look at all the
              facts, bring in expert witnesses, and talk with veterinarians and
              neighbors who may have knowledge of the incident. We do all we can
              to support our clients, so they reach their goal of justice.
            </p>
            <p className="mb-8">
              Dog bites and other animal attacks are very painful and scary. If
              the animal is not caught and examined, the victim may need to
              undergo a series of painful rabies or other shots to avoid serious
              illness. Dogs should be restrained and not allowed to run free,
              especially if they have a history of biting. Our Dog Bite and
              Animal Attack Attorneys understand the effects of animal bites and
              the expenses involved in treatments that might include surgery,
              reconstruction or amputation. We fight aggressively to get our
              clients the fair compensation they deserve.
            </p>
            <p className="mb-8">
              We serve many communities across Connecticut. Our animal bite
              attorneys help local CT clients recover financially from treatment
              expenses and any rehabilitation needed. We represent children and
              adults in their legal pursuit of justice that results from dog
              bite and animal attack injuries.
            </p>

            <h2>When is a Pet Owner Potentially Liable for Injuries?</h2>
            <p className="mb-8">
              When a person is injured as a result of a dog bite or animal
              attack, they may be able to file a lawsuit. The plaintiff must
              prove the following elements to succeed in a dog bite or animal
              attack lawsuit:
            </p>

            <ul className="list-disc list-outside ml-6 mb-8">
              <li>
                <strong>Causation:</strong> Connecticut law imposes strict
                liability for injuries that occur as a result of dog bites or
                other animal attacks. This means that a plaintiff must also
                prove that they have sustained an injury as a result of the dog
                bite or animal attack.
              </li>
              <li>
                <strong>Damages:</strong> The injury caused by the dog bite or
                animal attack must have caused either economic or non-economic
                damages. This means that property owners cannot be held liable
                unless the injured person incurred medical bills, lost wages,
                damage to their future earning capacity, or pain and suffering.
              </li>
            </ul>

            <h2>What compensation are you entitled to?</h2>
            <p className="mb-8">
              In personal injury cases, compensatory damages are awarded to
              compensate the injured person for the harm caused by dog bite or
              animal attack. Compensatory damages attempt to make the victim
              “whole” again and provide financial compensation for economic and
              non-economic losses. Also known as actual damages, economic losses
              that may be covered include:
            </p>

            <ul className="list-disc list-outside ml-6 mb-8">
              <li>
                <strong>Medical expenses:</strong> These damages cover the cost
                of hospital stays, doctor visits, physical therapy, prescription
                drugs, assistive devices, and similar expenses. When an injury
                is severe or permanent, the personal injury victim may also be
                awarded compensation for future medical expenses.
              </li>
              <li>
                <strong>Lost wages:</strong> If you must take time away from
                work to recover from your injuries you may be able to collect
                compensation for lost wages.
              </li>
              <li>
                <strong>Loss of earning capacity:</strong> When a person is
                unable to earn the same amount of money as he or she did prior
                to their accident, they may be awarded compensation for a loss
                of earning capacity.
              </li>
              <li>
                <strong>Pain and suffering:</strong> Damages for pain and
                suffering typically compensate the victim for the physical pain
                caused by their injuries. These damages may also include
                compensation for emotional distress, which refers to the
                anxiety, depression, fear, frustration, and other mental
                sufferings that can develop as a result of an injury.
              </li>
              <li>
                <strong>Loss of Consortium:</strong> The spouse of the injured
                party may be able to recover compensation for the loss of
                marital benefits. Loss of companionship, sexual relations,
                affection, and comfort are among the compensable marital
                benefits. Loss of consortium damages is typically awarded in
                cases of life-changing or permanent injuries.
              </li>
              <li>
                <strong>Additional Damages May be Available:</strong> In the
                State of Connecticut, if an animal’s owner has knowledge of the
                animal’s dangerous propensities, or that the animal is prone to
                bite or attack, the owner may be held liable for punitive
                damages in addition to the damages available to compensate the
                victim for their injuries. The attorneys at Bartlett & Grippe
                work hard to investigate the animal’s prior history, including
                researching veterinary records, town records and other resources
                to determine whether our clients may be entitled to additional
                compensation due to an animal owner’s recklessness.
              </li>
            </ul>
            <p className="mb-8">
              If you or a loved one has been bitten by an animal and received
              physical, psychological or emotional injuries, our law firm offers
              the skilled legal representation you need. Contact our Dog Bite
              and Animal Attack Attorneys at our Cheshire CT law offices for
              caring legal representation.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
